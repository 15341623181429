<template>
    <form
            :class="{'form-relative': isModalForm}"
            novalidate
            @submit.stop.prevent="organizationId ? edit() : save()"
    >
        <div :class="{'form-in-scroll': isModalForm}">
            <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
            <p v-if="isModalForm" class="subscription-info">Ваша подписка станет активна после получения оплаты. Для ускорения активации
                аккаунта, вы можете выслать скан платежного поручения на buh@bytorg.by, либо позвоните +375-33-722-88-33
            </p>
            <div class="form-block">
                <p class="form-block-name">Общая информация об организации</p>
                <div class="row">
                    <div class="col-8">
                        <div class="form-group">
                            <label class="form-control-label">Полное наименование организации (в соответствии со
                                свидетельством о регистрации)<span>*</span></label>
                            <input
                                    v-model.trim="form.fullNameOrganization"
                                    ref="fullNameOrganization"
                                    :class="{'is-invalid': $v.form.fullNameOrganization.$error}"
                                    type="text"
                                    class="form-control form-control-light"
                                    placeholder='Общество с ограниченной ответственностью "Организация"'
                            >
                            <div
                                    v-if="$v.form.fullNameOrganization.$dirty && !$v.form.fullNameOrganization.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.fullNameOrganization.$dirty && !$v.form.fullNameOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.fullNameOrganization.$params.maxLength.max}}
                                символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Краткое название организации<span>*</span></label>
                            <input
                                    v-model.trim="form.shortNameOrganization"
                                    ref="shortNameOrganization"
                                    :class="{'is-invalid': $v.form.shortNameOrganization.$error}"
                                    type="text"
                                    class="form-control form-control-light"
                                    placeholder='ООО "Организация"'
                            >
                            <div
                                    v-if="$v.form.shortNameOrganization.$dirty && !$v.form.shortNameOrganization.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.shortNameOrganization.$dirty && !$v.form.shortNameOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.shortNameOrganization.$params.maxLength.max}}
                                символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Страна<span>*</span></label>
                            <input
                                    v-model.trim="form.country"
                                    ref="country"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.country.$error}"
                                    placeholder="Республика Беларусь"
                            >
                            <div
                                    v-if="$v.form.country.$dirty && !$v.form.country.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.country.$dirty && !$v.form.country.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.country.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Регион<span>*</span></label>
                            <input
                                    v-model.trim="form.region"
                                    ref="region"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.region.$error}"
                                    placeholder="Гомельская область"
                            >
                            <div
                                    v-if="$v.form.region.$dirty && !$v.form.region.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.region.$dirty && !$v.form.region.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.region.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">УНП<span>*</span></label>
                            <input
                                    v-model.number.trim="form.unp"
                                    ref="unp"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.unp.$error}"
                                    placeholder="123456789"
                            >
                            <div
                                    v-if="$v.form.unp.$dirty && !$v.form.unp.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.unp.$dirty && (!$v.form.unp.minLength || !$v.form.unp.maxLength || !$v.form.unp.numeric)"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать 9 цифр
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-block">
                <p class="form-block-name">Адрес организации</p>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Почтовый индекс<span>*</span></label>
                            <input
                                    v-model.number.trim="form.postcode"
                                    ref="postcode"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.postcode.$error}"
                                    placeholder="246000"
                            >
                            <div
                                    v-if="$v.form.postcode.$dirty && !$v.form.postcode.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.postcode.$dirty && (!$v.form.postcode.minLength || !$v.form.postcode.maxLength || !$v.form.postcode.numeric)"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать 6 цифр
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Город / Населенный пункт<span>*</span></label>
                            <input
                                    v-model.trim="form.city"
                                    ref="city"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.city.$error}"
                                    placeholder="Гомель"
                            >
                            <div
                                    v-if="$v.form.city.$dirty && !$v.form.city.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.city.$dirty && !$v.form.city.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.city.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Улица</label>
                            <input
                                    v-model.trim="form.street"
                                    ref="street"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.street.$error}"
                                    placeholder="Советская"
                            >
                            <div
                                    v-if="$v.form.street.$dirty && !$v.form.street.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.street.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Дом</label>
                            <input
                                    v-model.trim="form.house"
                                    ref="house"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.house.$error}"
                                    placeholder="1"
                            >
                            <div
                                    v-if="$v.form.house.$dirty && !$v.form.house.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.house.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Корпус</label>
                            <input
                                    v-model.trim="form.housing"
                                    ref="housing"
                                    type="text"
                                    class="form-control form-control-light"
                                    placeholder="1"
                            >
                            <div
                                    v-if="$v.form.housing.$dirty && !$v.form.housing.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.housing.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Офис</label>
                            <input
                                    v-model.trim="form.office"
                                    ref="office"
                                    type="text"
                                    class="form-control form-control-light"
                                    placeholder="1"
                            >
                            <div
                                    v-if="$v.form.office.$dirty && !$v.form.office.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.office.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-block">
                <p class="form-block-name">Информация о руководителе организации</p>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Фамилия руководителя<span>*</span></label>
                            <input
                                    v-model.trim="form.lastNameHeadOrganization"
                                    ref="lastNameHeadOrganization"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.lastNameHeadOrganization.$error}"
                                    placeholder="Иванов"
                            >
                            <div
                                    v-if="$v.form.lastNameHeadOrganization.$dirty && !$v.form.lastNameHeadOrganization.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.lastNameHeadOrganization.$dirty && !$v.form.lastNameHeadOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более
                                {{$v.form.lastNameHeadOrganization.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Имя руководителя<span>*</span></label>
                            <input
                                    v-model.trim="form.firstNameHeadOrganization"
                                    ref="firstNameHeadOrganization"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.firstNameHeadOrganization.$error}"
                                    placeholder="Иван"
                            >
                            <div
                                    v-if="$v.form.firstNameHeadOrganization.$dirty && !$v.form.firstNameHeadOrganization.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.firstNameHeadOrganization.$dirty && !$v.form.firstNameHeadOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более
                                {{$v.form.firstNameHeadOrganization.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Отчество руководителя</label>
                            <input
                                    v-model.trim="form.middleNameHeadOrganization"
                                    ref="middleNameHeadOrganization"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.middleNameHeadOrganization.$error}"
                                    placeholder="Иванович"
                            >
                            <div
                                    v-if="$v.form.middleNameHeadOrganization.$dirty && !$v.form.middleNameHeadOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более
                                {{$v.form.middleNameHeadOrganization.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-control-label">Фамилия, Имя, Отчество руководителя в родительном
                                падеже</label>
                            <input
                                    v-model.trim="form.fullNameHeadOrganization"
                                    ref="fullNameHeadOrganization"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.fullNameHeadOrganization.$error}"
                                    placeholder="Иванова Ивана Ивановича"
                            >
                            <div
                                    v-if="form.fullNameHeadOrganization && $v.form.fullNameHeadOrganization.$dirty && !$v.form.fullNameHeadOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более
                                {{$v.form.fullNameHeadOrganization.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">Должность</label>
                            <input
                                    v-model.trim="form.positionInOrganization"
                                    ref="positionInOrganization"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.positionInOrganization.$error}"
                                    placeholder="Директор"
                            >
                            <div
                                    v-if="form.positionInOrganization && $v.form.positionInOrganization.$dirty && !$v.form.positionInOrganization.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.positionInOrganization.$params.maxLength.max}}
                                символов
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">Действующего на основании</label>
                            <input
                                    v-model.trim="form.actingOnBasis"
                                    ref="actingOnBasis"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.actingOnBasis.$error}"
                                    placeholder="Устава"
                            >
                            <div
                                    v-if="form.actingOnBasis && $v.form.actingOnBasis.$dirty && !$v.form.actingOnBasis.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.actingOnBasis.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-block">
                <p class="form-block-name">Банковские реквизиты</p>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Р/с банка<span>*</span></label>
                            <input
                                    v-model.trim="form.bankAccount"
                                    ref="bankAccount"
                                    type="text"
                                    class="form-control form-control-light form-control-uppercase"
                                    :class="{'is-invalid': $v.form.bankAccount.$error}"
                                    placeholder="BY11AAAA11111111111111111111"
                            >
                            <div
                                    v-if="$v.form.bankAccount.$dirty && !$v.form.bankAccount.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.bankAccount.$dirty && !$v.form.bankAccount.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.bankAccount.$params.maxLength.max}} символов
                            </div>
                            <div
                                    v-if="$v.form.bankAccount.$dirty && !$v.form.bankAccount.alphaNum"
                                    class="invalid-feedback"
                            >
                                Поле может содержать только цифры и буквы
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">БИК банка<span>*</span></label>
                            <input
                                    v-model.trim="form.bankIdentificationCode"
                                    ref="bankIdentificationCode"
                                    type="text"
                                    class="form-control form-control-light form-control-uppercase"
                                    :class="{'is-invalid': $v.form.bankIdentificationCode.$error}"
                                    placeholder="AAAAAA9A"
                            >
                            <div
                                    v-if="$v.form.bankIdentificationCode.$dirty && !$v.form.bankIdentificationCode.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.bankIdentificationCode.$dirty && !$v.form.bankIdentificationCode.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.bankIdentificationCode.$params.maxLength.max}}
                                символов
                            </div>
                            <div
                                    v-if="$v.form.bankIdentificationCode.$dirty && !$v.form.bankIdentificationCode.alphaNum"
                                    class="invalid-feedback"
                            >
                                Поле может содержать только цифры и буквы
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label class="form-control-label">Наименование банка<span>*</span></label>
                            <input
                                    v-model.trim="form.bankName"
                                    ref="bankName"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.bankName.$error}"
                                    placeholder='ОАО "Банк"'
                            >
                            <div
                                    v-if="$v.form.bankName.$dirty && !$v.form.bankName.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.bankName.$dirty && !$v.form.bankName.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.bankName.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-control-label">Адрес банка<span>*</span></label>
                            <input
                                    v-model.trim="form.bankAddress"
                                    ref="bankAddress"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.bankAddress.$error}"
                                    placeholder='Советская, 1, Гомель'
                            >
                            <div
                                    v-if="$v.form.bankAddress.$dirty && !$v.form.bankAddress.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.bankAddress.$dirty && !$v.form.bankAddress.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.bankAddress.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-block">
                <p class="form-block-name">Контактная информация</p>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-control-label">Контактное лицо<span>*</span></label>
                            <input
                                    v-model.trim="form.contactName"
                                    ref="contactName"
                                    type="text"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.contactName.$error}"
                                    placeholder='Иванов Иван'
                            >
                            <div
                                    v-if="$v.form.contactName.$dirty && !$v.form.contactName.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.contactName.$dirty && !$v.form.contactName.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.contactName.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group mb-none">
                            <label class="form-control-label">Телефон контактного лица<span>*</span></label>
                            <input
                                    v-model.trim="form.contactPhoneNumber"
                                    ref="contactPhoneNumber"
                                    type="tel"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.contactPhoneNumber.$error}"
                                    placeholder="+375291234567"
                            >
                            <div
                                    v-if="$v.form.contactPhoneNumber.$dirty && !$v.form.contactPhoneNumber.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.contactPhoneNumber.$dirty && !$v.form.contactPhoneNumber.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.contactPhoneNumber.$params.maxLength.max}}
                                символов
                            </div>
                            <div
                                    v-if="form.contactPhoneNumber && $v.form.contactPhoneNumber.$dirty && !$v.form.contactPhoneNumber.validateNumber"
                                    class="invalid-feedback"
                            >
                                Некорректный номер телефона
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group mb-none">
                            <label class="form-control-label">Факс</label>
                            <input
                                    v-model.trim="form.contactFax"
                                    ref="contactFax"
                                    type="tel"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.contactFax.$error}"
                                    placeholder="80232111111"
                            >
                            <div
                                    v-if="form.contactFax && $v.form.contactFax.$dirty && !$v.form.contactFax.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.contactFax.$params.maxLength.max}} символов
                            </div>
                            <div
                                    v-if="form.contactFax && $v.form.contactFax.$dirty && !$v.form.contactFax.validateNumber"
                                    class="invalid-feedback"
                            >
                                Некорректный номер факса
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group mb-none">
                            <label class="form-control-label">E-mail<span>*</span></label>
                            <input
                                    v-model.trim="form.contactEmail"
                                    ref="contactEmail"
                                    type="email"
                                    class="form-control form-control-light"
                                    :class="{'is-invalid': $v.form.contactEmail.$error}"
                                    placeholder='ivanov@gmail.com'
                            >
                            <div
                                    v-if="$v.form.contactEmail.$dirty && !$v.form.contactEmail.required"
                                    class="invalid-feedback"
                            >
                                Поле обязательно для заполнения
                            </div>
                            <div
                                    v-if="$v.form.contactEmail.$dirty && !$v.form.contactEmail.email"
                                    class="invalid-feedback"
                            >
                                Некорректный E-mail
                            </div>
                            <div
                                    v-if="$v.form.contactEmail.$dirty && !$v.form.contactEmail.maxLength"
                                    class="invalid-feedback"
                            >
                                Поле должно содержать не более {{$v.form.contactEmail.$params.maxLength.max}} символов
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="['form-footer', {'form-footer-fixed': isModalForm}]">
            <template v-if="isModalForm">
                <input
                        id="agreement"
                        type="checkbox"
                        class="checkbox"
                        v-model="agreement"
                >
                <label for="agreement"
                       class="checkbox-label"
                       :class="{'is-invalid': isModalForm && agreementIsTouched && !agreement}"
                       @change="agreementIsTouched = true"
                >
                    <span>
                        Согласен с условиями
                        <button
                                class="service-agreement-btn"
                                @click="downloadServiceAgreement"
                                type="button"
                        >
                            договора оказания услуг
                        </button>
                    </span>
                </label>
            </template>
            <button
                    class="btn btn-middle"
                    type="submit"
                    :disabled="isOrganizationRequesting || isSubscriptionRequesting"
            >
                {{btnText}}
            </button>
        </div>
    </form>
</template>

<script>
    import {alphaNum, email, maxLength, minLength, numeric, required} from 'vuelidate/lib/validators'
    import {mapActions, mapGetters} from 'vuex'
    import ErrorMessage from '@/components/common/ErrorMessage'
    import api from "@/application"
    import {fileDownload} from "@/utils/fileDownload"

    export default {
        name: "OrganizationForm",
        props: ['isModalForm', 'btnText', 'callBackFunc'],
        data() {
            return {
                form: {
                    fullNameOrganization: null,
                    shortNameOrganization: null,
                    country: "Республика Беларусь",
                    region: null,
                    unp: null,
                    postcode: null,
                    city: null,
                    street: null,
                    house: null,
                    housing: null,
                    office: null,
                    lastNameHeadOrganization: null,
                    firstNameHeadOrganization: null,
                    middleNameHeadOrganization: null,
                    fullNameHeadOrganization: null,
                    positionInOrganization: null,
                    actingOnBasis: null,
                    bankAccount: null,
                    bankIdentificationCode: null,
                    bankName: null,
                    bankAddress: null,
                    contactName: null,
                    contactPhoneNumber: null,
                    contactFax: null,
                    contactEmail: null,
                },
                agreement: false,
                agreementIsTouched: false,
                errorMessage: null
            }
        },
        components: {
            ErrorMessage
        },
        computed: {
            ...mapGetters('organization', ['organizationId', 'actingOnBasis', 'bankAccount', 'bankAddress',
                'bankIdentificationCode', 'bankName', 'city', 'contactEmail', 'contactFax', 'contactName',
                'contactPhoneNumber', 'country', 'firstNameHeadOrganization', 'fullNameHeadOrganization',
                'fullNameOrganization', 'house', 'housing', 'lastNameHeadOrganization', 'middleNameHeadOrganization',
                'office', 'positionInOrganization', 'postcode', 'region', 'shortNameOrganization', 'street', 'unp']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('organization', {isOrganizationRequesting: 'isRequesting'}),
            ...mapGetters('subscriptions', {isSubscriptionRequesting: 'isRequesting'}),
        },
        validations: {
            form: {
                fullNameOrganization: {required, maxLength: maxLength(100)},
                shortNameOrganization: {required, maxLength: maxLength(50)},
                unp: {required, numeric, minLength: minLength(9), maxLength: maxLength(9)},
                positionInOrganization: {maxLength: maxLength(50)},
                actingOnBasis: {maxLength: maxLength(50)},
                country: {required, maxLength: maxLength(50)},
                region: {required, maxLength: maxLength(50)},
                city: {required, maxLength: maxLength(50)},
                street: {maxLength: maxLength(50)},
                house: {maxLength: maxLength(5)},
                housing: {maxLength: maxLength(20)},
                office: {maxLength: maxLength(5)},
                postcode: {required, numeric, minLength: minLength(6), maxLength: maxLength(6)},
                firstNameHeadOrganization: {required, maxLength: maxLength(50)},
                middleNameHeadOrganization: {maxLength: maxLength(50)},
                lastNameHeadOrganization: {required, maxLength: maxLength(50)},
                fullNameHeadOrganization: {maxLength: maxLength(60)},
                bankName: {required, maxLength: maxLength(50)},
                bankAddress: {required, maxLength: maxLength(50)},
                bankAccount: {required, alphaNum, maxLength: maxLength(50)},
                bankIdentificationCode: {required, alphaNum, maxLength: maxLength(50)},
                contactName: {required, maxLength: maxLength(50)},
                contactPhoneNumber: {
                    required,
                    maxLength: maxLength(13),
                    validateNumber: function (contactPhoneNumber) {
                        const phonePattern = /^[+]?[0-9]*[(]?[0-9]*[)]?[-\s./0-9]*$/
                        return phonePattern.test(contactPhoneNumber)
                    }
                },
                contactEmail: {required, email, maxLength: maxLength(50)},
                contactFax: {
                    maxLength: maxLength(13),
                    required: false,
                    validateNumber: function (contactFax) {
                        const phonePattern = /^[+]?[0-9]*[(]?[0-9]*[)]?[-\s./0-9]*$/
                        return !contactFax || phonePattern.test(contactFax)
                    }
                },
            }
        },
        methods: {
            ...mapActions('organization', ['editOrganization', 'saveOrganization', 'getOrganization']),
            scrollToInputError() {
                for (let key in Object.keys(this.$v.form)) {
                    const input = Object.keys(this.$v.form)[key]
                    if (this.$v.form[input].$error) {
                        this.$refs[input].focus()
                        break
                    }
                }
            },
            async edit() {
                if (this.isNotValid()) {
                    this.$v.$touch()
                    this.scrollToInputError();
                    this.agreementIsTouched = true
                    return
                }
                try {
                    await this.editOrganization({organizationId: this.organizationId, formData: this.form})
                    this.isModalForm && await this.callBackFunc()
                    this.requestSuccess()
                } catch (e) {
                    this.errorMessage = e.message
                }
            },
            async save() {
                if (this.isNotValid()) {
                    this.$v.$touch()
                    this.scrollToInputError();
                    this.agreementIsTouched = true
                    return
                }
                try {
                    await this.saveOrganization({...this.form, profile: `/${this.profileId}`})
                    this.isModalForm && await this.callBackFunc()
                    this.requestSuccess()
                } catch (e) {
                    this.errorMessage = e.message
                }
            },
            async downloadServiceAgreement() {

                try {
                    const response = await api.pdf.getServiceAgreement()
                    fileDownload(response)
                } catch (e) {
                    this.$toast.error(e.message)
                }

            },
            isNotValid() {
                return this.$v.$invalid || (this.isModalForm && !this.agreement)
            },
            requestSuccess() {
                this.agreementIsTouched = false
                if (this.isModalForm) {
                    this.$modal.close()
                }
                this.$toast.success('Изменения успешно сохранены')
            }
        },
        created() {
            this.organizationId && this.getOrganization(this.profileId)
        },
        mounted() {
            this.form.organizationId = this.organizationId
            this.form.actingOnBasis = this.actingOnBasis
            this.form.bankAccount = this.bankAccount
            this.form.bankAddress = this.bankAddress
            this.form.bankIdentificationCode = this.bankIdentificationCode
            this.form.bankName = this.bankName
            this.form.city = this.city
            this.form.contactEmail = this.contactEmail
            this.form.contactFax = this.contactFax
            this.form.contactName = this.contactName
            this.form.contactPhoneNumber = this.contactPhoneNumber
            this.form.country = this.country || this.form.country
            this.form.firstNameHeadOrganization = this.firstNameHeadOrganization
            this.form.fullNameHeadOrganization = this.fullNameHeadOrganization
            this.form.fullNameOrganization = this.fullNameOrganization
            this.form.house = this.house
            this.form.housing = this.housing
            this.form.lastNameHeadOrganization = this.lastNameHeadOrganization
            this.form.middleNameHeadOrganization = this.middleNameHeadOrganization
            this.form.office = this.office
            this.form.positionInOrganization = this.positionInOrganization
            this.form.postcode = this.postcode
            this.form.region = this.region
            this.form.shortNameOrganization = this.shortNameOrganization
            this.form.street = this.street
            this.form.unp = this.unp
        }
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/bootstrapGrid.less';
    @import '../../assets/less/variables.less';

    .subscription-info {
        margin-bottom: 16px;
        line-height: 1.7;
        color: @text-color-light;
    }

    .form-in-scroll .form-group-short-name .form-control-label {
        min-height: 34px;

    }

    .service-agreement-btn {

        text-decoration: underline;
    }

    @media (max-width: 1041px) {
        .form-group-short-name .form-control-label {
            min-height: 34px;
        }
    }
</style>
