<template>
    <nav
            class="navBar"
            :class="{'isOpen': isOpen}"
            ref="navBar"
    >
        <div>
            <div class="menuHeader">
                <router-link
                        :to="homePath"
                        exact
                >
                    <img class="mainIcon" src="../../assets/img/bytorg_Logo.svg" alt="" width="84" height="40">
                </router-link>
                <div
                    @click="toggleNav"
                >
                    <img  class="collapsedMenuBtn" src="../../assets/img/menu-collapse.svg" alt="">
                </div>
            </div>
            <ul class="links">
                <router-link
                        :to="{name: getRouteName(route)}"
                        v-for="(route, index) in getRoutes"
                        :key="index"
                        tag="li"
                        class="link"
                        active-class="active"
                >
                    <div>
                        <div class="menuIcon">
                            <ico :name="route.icon"/>
                        </div>
                        <div class="menuText">
                            {{ route.display }}
                        </div>
                    </div>
                </router-link>
                <router-link
                    :to="{name: 'sendNotifications'}"
                    v-if="this.authorities.includes('ROLE_ADMIN') && !this.mindControlledUser"
                    tag="li"
                    class="link"
                    active-class="active"
                >
                    <div>
                        <div class="menuIcon">
                            <ico name="icoMail"/>
                        </div>
                        <div class="menuText">
                            Уведомления
                        </div>
                    </div>
                </router-link>
                <li
                        class="link"
                        v-if="authorities.includes('ROLE_SUBSCRIBED_USER')"
                        data-v-step="start-tour-link"
                        @click="onStartTourBtnClick"
                >
                    <div>
                        <div class="menuIcon">
                            <ico name="icoFlag"/>
                        </div>
                        <div class="menuText">
                            Запустить подсказки
                        </div>
                    </div>
                </li>
            </ul>

        </div>
        <div>
            <div
                v-if="daysTillEndOfSubscription < getTrialSubscriptionDaysCount && !hasNextPaidSubscription"
                :style="cssVars"
            >
                <div class="daysLeftContainer">
                    <div class="innerDayContainer">
                        <div class="digit">{{Math.max(0, daysTillEndOfSubscription)}}</div>
                        <div class="labelDaysLeft">
                            <div>{{getText}}</div>
                            <div> {{daysTillEndOfSubscription === 1 ? 'остался' : 'осталось'}}</div>
                        </div>
                    </div>
                    <button
                            class="subscriptionBtn"
                            @click="onSubscriptionBtnClick"
                            type="button"
                    >
                        Продлить подписку
                    </button>
                </div>
                <div class="collapsedDaysLeftContainerContainer">
                    <button
                            class="collapsedSubscriptionBtn"
                            @click="onSubscriptionBtnClick"
                            type="button"
                    >
                        <span style="font-size: x-large;"><strong>{{Math.max(0, daysTillEndOfSubscription)}}</strong>
                            <!-- <ico name="icoAvTimer"/> -->
                        </span>
                    </button>
                </div>
            </div>
            <ul class="links">
                <router-link
                    :to="{name: 'newNotifications'}"
                    v-if="!this.authorities.includes('ROLE_ADMIN') || this.mindControlledUser"
                    tag="li"
                    class="link"
                    active-class="active"
                >
                    <div>
                        <div class="menuIcon" v-bind:class="{ notificationIcon: newNotifications.length > 0}">
                            <ico name="icoMail"/>
                        </div>
                        <div class="menuText">
                            Уведомления
                        </div>
                    </div>
                </router-link>
            </ul>

            <hr class="line">
            <div class="userInfoPanel">
                <div class="userIcon">
                    <ico name="icoAccountCircle"/>
                </div>
                <div class="userInfoBlock">
                    <div
                            v-if="organizationId"
                            class="userName"
                            :title="shortNameOrganization"
                    >
                        {{shortNameOrganization}}
                    </div>
                    <div
                            class="userEmail"
                            :title="userEmail"
                    >{{userEmail}}
                    </div>
                </div>
                <button
                        class="logoutBlock"
                        @click="logout"
                        type="button"
                >
                    <ico name="icoExitToApp" color="#ffffff"/>
                </button>
            </div>
        </div>
    </nav>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import {getTranslateDays} from "@/utils/translateWord"
    import {homePath} from "@/router/home"
    import SubscriptionDateModal from '@/components/profilePage/SubscriptionDateModal'
    import {SubscriptionDateModalProperties} from '@/utils/modalsProperties'
    import {disconnectWS} from "@/utils/stompClient"
    import {filter, includes} from "lodash"
    import {serviceUnavailable} from "@/utils/constants/errorMessages"
    import {eventBus} from "../../main"
    import Configuration from "@/configuration"
    import {reinitCancelToken, source} from "../../application/requests/notificationsRequests"
    import Vue from "vue"

    export default {
        name: "HamburgerMenu",

        data() {
            return {
                userName: '',
                homePath,
                isOpen: false,
                permissionNotification: false,
                isSubscriptionsLoaded: false,
            }
        },

        computed: {
            ...mapGetters('auth', ['email', 'authorities']),
            ...mapGetters('impersonalization', ['mindControlledUser',]),
            ...mapGetters('organization', ['shortNameOrganization', 'organizationId']),
            ...mapGetters('profile', ['daysTillEndOfSubscription', 'profileId', 'hintsEnabled']),
            ...mapGetters('notifications', ['newNotifications']),
            ...mapGetters('subscriptions', ['generalSubscriptions']),
            getRoutes() {

                return filter(this.$router.options.routes, it => it.meta && it.meta.layout === 'main' && (!it.meta.permission || this.checkPermissions(it.meta.permission)))
            },
            cssVars() {
                const color = this.daysTillEndOfSubscription > 0 ? "#ffb645" : "red"

                return {
                    '--bg-color': color
                }
            },
            getText() {
                return getTranslateDays(Math.max(0, this.daysTillEndOfSubscription))
            },
            userEmail() {

                return this.mindControlledUser ?? this.email
            },
            getTrialSubscriptionDaysCount() {
                return parseInt(Configuration.value('trialSubscriptionDaysCount')) + 1
            },
            hasNextPaidSubscription() {
                if (!this.generalSubscriptions || this.generalSubscriptions.length === 0) {
                    return false;
                }
                const today = new Date().setHours(0, 0, 0, 0);
                const validSubscriptions = this.generalSubscriptions.map(sub => ({
                    ...sub,
                    id: sub.id,
                    startDate: new Date(new Date(sub.startDate).setHours(0, 0, 0, 0)),
                    endDate: new Date(new Date(sub.endDate).setHours(0, 0, 0, 0)),
                    status: sub.status.id,
                }))
                .filter(sub => sub.status === 2)
                .sort((a, b) => a.startDate - b.startDate);

                // active paid sub
                const currentSubscription = validSubscriptions.find(sub => {
                    return sub.startDate.getTime() <= today && sub.endDate.getTime() >= today;
                });

                // next paid sub
                if (currentSubscription) {
                    const nextPaidSubscription = validSubscriptions.some(sub => sub.startDate.getTime() > currentSubscription.endDate.getTime());
                    return nextPaidSubscription;
                }

                // no active sub, but future sub
                const futurePaidSubscription = validSubscriptions.some(sub => {
                    return sub.startDate.getTime() > today;
                });

                return futurePaidSubscription || this.daysTillEndOfSubscription >= 7;
            }
        },

        methods: {
            ...mapActions('auth', ['logoutUser']),
            ...mapActions(['clearAll']),
            ...mapActions('profile', ['updateHintsEnabled']),
            ...mapActions('subscriptions', ['getSubscriptions']),
            async loadSubscriptions() {
                try {
                    await this.getSubscriptions(this.profileId);
                    this.isSubscriptionsLoaded = true;
                } catch (error) {
                    console.error('Ошибка при загрузке подписок:', error);
                }
            },
            toggleNav() {
                this.isOpen = !this.isOpen
            },
            async logout() {

                if (this.mindControlledUser) {
                    this.$toast.error('Покиньте режим')
                    return
                }

                Vue.notify({
                    group: 'templateNotification',
                    clean: true,
                })

                try {
                    await this.$router.push(homePath)
                    localStorage.removeItem('sortFilter')
                    source.cancel()
                    reinitCancelToken()
                    this.logoutUser()
                    this.clearAll()
                    disconnectWS()
                } catch (e) {
                    this.$toast.error(serviceUnavailable + "(1)")
                }
            },
            async onSubscriptionBtnClick() {
                await this.$router.push({name: 'subscription'})
                this.$modal.show(
                    SubscriptionDateModal,
                    null,
                    SubscriptionDateModalProperties
                )
            },
            async onStartTourBtnClick() {
                if (!this.hintsEnabled && includes(this.authorities, 'ROLE_SUBSCRIBED_USER')) {
                    await this.updateHintsEnabled({id: this.profileId, hintsEnabled: true})
                    eventBus.$emit('closePurchaseTableSettingsMenu')
                    this.$tours['startTour'].start()
                }
            },
            getRouteName(route) {
                return route.name ? route.name : route.children[0].name;
            },
            checkPermissions(permissions) {
                let result;
                if (Array.isArray(permissions)) {
                    result = permissions.some(permission => includes(this.authorities, permission))
                } else {
                    result = includes(this.authorities, permissions)
                }
                return result;
            },
            closeNavOnClickOutside(event) {
                if (this.isOpen && this.$refs.navBar && !this.$refs.navBar.contains(event.target)) {
                    this.isOpen = false;
                }
            }
        },
        mounted() {
            document.addEventListener("mousedown", this.closeNavOnClickOutside);
            this.loadSubscriptions();
        },
        beforeDestroy() {
            document.removeEventListener("mousedown", this.closeNavOnClickOutside);
        },
    }

</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .navBar {
        z-index: 100;
        color: white;
        background-color: @bg-color;
        height: 100%;
        width: 64px;
        transition: .3s ease width;
        position: fixed;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navBar.isOpen {
        width: 288px;

        .links > .link.active > div > div.menuText {
            opacity: 1 !important;
        }

        .menuHeader {
            padding: 16px 24px 17px;
        }

        .links > li > div > .menuText {
            opacity: 1;
        }

        .userInfoPanel {
            padding-left: 24px;
        }

        .userInfoBlock {
            opacity: 1;
        }

        .logoutBlock {
            opacity: 1;
        }

        .mainIcon {
            display: block;
        }

        .collapsedDaysLeftContainerContainer {
            display: none;
        }

        .daysLeftContainer {
            display: block;
        }

        .collapsedMenuBtn {
            transform: scale(1, 1);
            cursor: pointer;
        }
    }

    .links > .link.active > div > .menuText {
        opacity: 0 !important;
    }

    .mainIcon {
        display: none;
    }

    .collapsedDaysLeftContainerContainer {
        display: block;
    }

    .daysLeftContainer {
        display: none;
    }

    .collapsedMenuBtn {
        transform: scale(-1, 1);
        cursor: pointer;
    }

    .links {
        display: flex;
        flex-direction: column;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
    }

    .link {
        list-style-type: none;
        height: 64px;
        padding: 20px;
        cursor: pointer;

        &:hover {
            background-color: #3c3e3a;
        }

        div {
            color: white;
            height: 24px;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
        }

        &.active {
            background-color: @active-router-link-color;

            div {
                opacity: 1 !important;
            }
        }
    }

    .menuText {
        font-weight: 500;
        transition: .3s ease opacity;
        opacity: 0;
        margin: 3px auto auto 16px;
        font-size: 16px;
        letter-spacing: 0.31px;

    }

    .menuIcon {
        opacity: 0.7;
        position: relative;
    }

    .notificationIcon::after{
        content: '';
        position: absolute;
        border-radius: 50%;
        background-color: #EB3223;
        width: 8px;
        height: 8px;
        top: 0;
        right: 0;

    }

    .menuHeader {
        display: flex;
        padding: 16px 20px;
        justify-content: space-between;
        align-items: center;
        height: 73px;
    }

    .daysLeftContainer {
        margin: 24px;
        background-color: #3c3e3a;
        height: 140px;
        border-radius: 5px;
        padding: 16px;
    }

    .subscriptionBtn {
        background-color: var(--bg-color);
        width: 100%;
        height: 48px;
        border-radius: 5px;
        color: white;
        white-space: nowrap;
        transition: none;
        font-weight: 500;
    }

    .collapsedSubscriptionBtn {
        border-radius: 5px;
        background-color: var(--bg-color);
        color: white;
        width: 48px;
        height: 48px;
        transition: none;
    }

    .collapsedDaysLeftContainerContainer {
        margin: 10px 8px 10px 8px;
        width: 48px;
        height: 48px;
    }

    .digit {
        font-size: 48px;
    }

    .labelDaysLeft {
        display: flex;
        padding-left: 12px;
        height: 14px;
        letter-spacing: 0.29px;
        font-weight: 500;
        white-space: nowrap;


        div:last-child {
            margin-left: 5px;
        }
    }

    .innerDayContainer {
        opacity: 1;
        width: 192px;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        color: var(--bg-color);
        height: 48px;
        margin: 0 0 12px;
    }

    .line {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid @text-color-light;
        margin: 0;
        padding: 0;
    }

    .userInfoPanel {
        transition: .3s ease all;
        overflow: hidden;
        white-space: nowrap;
        height: 88px;
        padding: 24px 24px 24px 20px;
        margin: 0;
        display: inline-flex;
    }

    .userInfoBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: .3s ease opacity;
        margin: 0 24px;
        width: 136px;
        height: 40px;
        opacity: 0;
    }

    .logoutBlock {
        transition: .3s ease opacity;
        cursor: pointer;
        height: 24px;
        width: 24px;
        margin-top: 8px;
        margin-bottom: 8px;
        opacity: 0;
    }

    .userIcon {
        margin: 8px 0;
        width: 24px;
        height: 24px;
        opacity: 0.7;
        cursor: pointer;
    }

    .userName {
        height: 24px;
        text-align: left;
        letter-spacing: .3px;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .userEmail {
        height: 16px;
        opacity: 0.5;
        text-align: left;
        letter-spacing: .3px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

</style>
